<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-store-list modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>เช็คราคาสินค้าแบบจัดส่ง</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <store-product-selection :product="product"></store-product-selection>

                        <store-delivery-checker
                            @submitArea="submitArea($event)">
                        </store-delivery-checker>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import ThaiAddress from '@/mixins/thaiAddress';
import OrderService from '@/services/orderService';
import StoreService from '@/services/storeService';
import Constants from '@/variables/constants';
import StoreProductSelection from '@/components/store/ProductSelection';
import StoreDeliveryChecker from '@/components/store/StoreDeliveryChecker';

export default {
    mixins: [ ThaiAddress ],
    components: { StoreProductSelection, StoreDeliveryChecker },
    data() {
		return {
            isActive: false,
            deliveryArea: null
		};
	},
	props: {
        isDisplay: {
            type: Boolean,
            required: true
        },
        product: {
            type: Object,
            required: false
        },
        storeId: {
            type: String,
            required: true
        },
        trackingReferrer: {
            type: String,
            required: false
        }
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        async submitArea(deliveryArea) {
            this.deliveryArea = deliveryArea;
            let loader = this.$modalLoader.render();

            const param = {
				sellerProductId: this.product.sellerProductId,
                deliverArea: this.getDeliveryData()
			};
            const result = await StoreService.checkStoreDeliverable(param);

            loader.hide();

            if (result.data.isDeliverable) {
                const vueObject = {
                    modalLoader: this.$modalLoader,
                    router: this.$router,
                    gtag: this.$gtag,
                    gtm: this.$gtm,
                    root: this.$root,
                    swal: this.$swal
                };
                const param = {
                    sellerProductId: this.product.sellerProductId,
                    storeId: this.storeId,
                    orderType: Constants.ORDER_TYPE.DELIVERY,
                    addressDistrictId: result.data.addressDistrictId,
                    referer: this.trackingReferrer
                };
                const gtmData = {
                    product: this.product,
                    type: 'delivery',
                    sellerProductId: this.product.sellerProductId,
                    storeId: this.storeId,
                    price: this.product.priceDelivery
                };

                OrderService.toggleAddCartEvent(vueObject, param, gtmData);
            } else {
                this.$swal(Helper.warningAlert('ขออภัยครับ', 'สินค้าที่เลือก ยังไม่มีบริการจัดส่งในพื้นที่ของท่าน'));
            }
        },
        getDeliveryData() {
            if (this.deliveryArea) {
                const deliveryData = this.deliveryArea.split(this.mixinsThaiAddressSeparator);

                return {
                    tumbon: deliveryData[this.mixinsThaiAddressDataIndex.tumbon],
                    amphur: deliveryData[this.mixinsThaiAddressDataIndex.amphur],
                    province: deliveryData[this.mixinsThaiAddressDataIndex.province],
                    postcode: deliveryData[this.mixinsThaiAddressDataIndex.postcode]
                }
            }
            
            return null;
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-store-list {
    max-width: 1200px;

    .filter {
        .loading {
            height: 300px;
        }
    }

    .content-mobile-filter {
        position: static;
        margin-bottom: 8px;
    }

    .delivery {
        border: 1px dotted #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 300px;
        @include borderRadius(5px);

        .wrapper {
            position: relative;
            max-width: 500px;
            width: 100%;
            padding: 25px 25px 20px;
            border: 1px solid #ccc;
            border-top: none;
            @include boxShadow(0 0 10px rgba(100, 100, 100, 0.1));
            
            .decoration {
                @extend .deliveryDecoration;
            }
        }

        .input-field {
            margin-bottom: 12px;

            label {
                color: #000;
                margin-bottom: 5px;

                .icon {
                    margin-right: 5px;
                    opacity: 0.7;
                }
            }
        }

        .action {
            text-align: center;

            .btn {
                padding: 5px 12px;
                font-size: 13px;
            }
        }
    }

    .store {
        position: relative;

        .loading {
            height: 300px;
        }
    }

    .area {
        position: relative;
        padding: 15px 15px 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-top: none;
        font-size: 14px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: $screenExtraSmall) {
            font-size: 13px;
            display: block;
        }

        .decoration {
            @extend .deliveryDecoration;
        }

        label {
            margin-right: 10px;
            font-weight: bold;

            @media only screen and (max-width: $screenExtraSmall) {
                width: 100%;
            }
        }

        span {
            margin-right: 20px;

            @media only screen and (max-width: $screenExtraSmall) {
                display: block;
                width: 100%;
            }

            .icon {
                margin-right: 5px;
            }
        }

        .btn {
            font-size: 13px;

            @media only screen and (max-width: $screenExtraSmall) {
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }
}

::v-deep .input-field {
    .typeahead {
        input {
            padding: 10px 15px;
            border: none;
            background: #eee;
        }
    }
}
</style>