<template>
	<div class="promotion">
		<div class="container">
			<div class="suggest-search">
				<div class="loading" v-if="!isSuggestTagReady">
					<loader-content :isDisplay="true"></loader-content>
				</div>
				<suggest-tags :tags="suggestTags" v-if="isSuggestTagReady"></suggest-tags>
			</div>

			<gadget-ads-banner :banner="topBanner" name="bannerPagePromotion" :isBannerReady="isContentReady"></gadget-ads-banner>

			<section class="mt-3 mb-2">
				<div class="loading" v-if="!isContentReady || !isRelateContentReady">
					<loader-content :isDisplay="true"></loader-content>
				</div>
				<blog-detail v-if="isContentReady && isRelateContentReady" 
					:content="promotionDetail" 
					:relatePromotions="relatePromotions"
					:relateArticlesLatest="relateArticlesLatest"
					:relateArticlesByCategory="relateArticlesByCategory">
				</blog-detail>
			</section>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PromotionService from '@/services/promotionService';
import MasterService from '@/services/masterService';
import SuggestTags from '@/components/gadget/SuggestTags';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import BlogDetail from '@/components/blog/BlogDetail';
import RouterPath from '@/router/path';

export default {
	components: {
		SuggestTags,
		GadgetAdsBanner,
		BlogDetail
	},
	data() {
		return {
			isSuggestTagReady: false,
			isContentReady: false,
			isRelateContentReady: false,
			topBanner: null,
			promotionDetail: null,
			suggestTags: null,
			relatePromotions: [],
			relateArticlesLatest: [],
			relateArticlesByCategory: [],
			routerPath: RouterPath,
			canonicalUrl: ''
		};
	},
	mounted() {
		this.getSuggestTags();
        this.getContent();
		this.getRelated();
    },
    methods: {
		async getSuggestTags() {
			this.isSuggestTagReady = false;

            const result = await MasterService.getSuggestTags();

			if (result.data) {
				this.suggestTags = result.data
				this.isSuggestTagReady = true;
			}
		},
        async getContent() {
            this.isContentReady = false;

			const param = this.setupParamContent();
            const result = await PromotionService.getPromotionDetail(param);

			if (result.data) {
				this.canonicalUrl = location.protocol + "//" + window.location.host + RouterPath.PROMOTION + '/' + decodeURI(result.data.content.urlParam);
				this.topBanner = result.data.ads;
				this.promotionDetail = result.data.content;
				this.isContentReady = true;
			} else {
				this.$router.push(RouterPath.NOT_FOUND);
			}
        },
		setupParamContent() {
			const param = {
				key: Helper.splitDotParam(this.$route.params.key),
				urlReferrer: document.referrer
			};

			return param;
		},
		async getRelated() {
			this.isRelateContentReady = false;
			
            const param = {
				key: Helper.splitDotParam(this.$route.params.key)
			};

            const result = await PromotionService.getPromotionRelated(param);

			this.relatePromotions = result.data.promotions;
            this.relateArticlesLatest = result.data.articlesLatest;
            this.relateArticlesByCategory = result.data.articlesByCategory;

			this.isRelateContentReady = true;
        }
    },
	metaInfo() {
		const title = this.promotionDetail ? this.promotionDetail.title + ' | YELLOWTiRE' : Helper.metaDefault().title;
		const desc = this.promotionDetail ? this.promotionDetail.shortDesc : Helper.metaDefault().desc;
		const image = this.promotionDetail ? this.promotionDetail.picture : location.origin + Helper.metaDefault().logo;
		const author = this.promotionDetail ? this.promotionDetail.authorProfile?.name : location.origin + Helper.metaDefault().author;
		const publisher = this.promotionDetail ? this.promotionDetail.publisher : location.origin + Helper.metaDefault().publisher;
		const keyword = this.promotionDetail ? this.promotionDetail.keyword : location.origin + Helper.metaDefault().keyword;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: author},
				{ vmid: 'publisher', name: 'publisher', content: publisher },
				{ vmid: 'keyword', name: 'keywords', content: keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ],
			link: [
				{ rel: 'canonical', href: this.canonicalUrl }
			]
        }
	}
}
</script>

<style lang="scss" scoped>
.promotion {
	.suggest-search {
		margin: 15px 0;
		
		.loading {
			height: 70px;
		}
	}

	.loading {
		height: 500px;
	}
}
</style>