import Helper from '@/services/helper';

export default {
    async getAboutUsContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/content/about-us');
        
        return result;
    },
    async getHelpInfoEU() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/content/help-info-eu');
        
        return result;
    },
    async getHelpInfoTSI() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/content/help-info-tsi');
        
        return result;
    }
}