<template>
    <section class="product-grid">
        <div class="product" 
            :class="grid"
            v-for="(item, index) in products"
            :key="index">
            <product-box
                @purchaseDelivery="purchaseDelivery($event)"
                @setCompare="setCompare($event)"
                :product="item"
                :networkId="networkId"
                :type="type"
                :store="store"
                :isShowCompare="isShowCompare"
                :trackingReferrer="trackingReferrer"
                :initStoreFilter="initStoreFilter"
                :tagSelections="tagSelections">
            </product-box>
        </div>

        <div class="no-data" v-if="products.length === 0">
			<b-icon icon="box-seam"></b-icon>
            <span>ไม่พบรายการสินค้า</span>
		</div>
    </section>
</template>

<script>
import Constants from '@/variables/constants';
import ProductBox from '@/components/product/ProductBox';

export default {
    components: {
        ProductBox
    },
    props: {
        products: {
            type: [ Array, Object ],
            required: true
        },
        networkId: {
            type: String,
            required: false
        },
        grid: {
            type: String,
            required: false,
            default: () => ''
        },
        store: {
            type: Object,
            required: false
        },
        type: {
            type: String,
            required: false,
            default: Constants.ITEM_BOX_TYPE.GENERAL
        },
        isShowCompare: {
            type: Boolean,
            required: false,
            default: false
        },
        trackingReferrer: {
            type: String,
            required: false,
        },
        initStoreFilter: {
            type: Array,
            required: false
        },
        tagSelections: {
            type: Array,
            required: false
        }
    },
    methods: {
        purchaseDelivery(data) {
            this.$emit('purchaseDelivery', data);
        },
        setCompare(item) {
            this.$emit('setCompare', item);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.product-grid {
    @include gridContentWrapper(0 -2px);

    .product {
        display: block;
        padding: 0 2px 4px;
        width: 25%;

        @media only screen and (max-width: $screenLarge) {
            width: 33.33333%;
        }

        @media only screen and (max-width: $screenSmall) {
            width: 50%;
            padding: 0 2px 2px;
        }

        &.column-5 {
            width: 20%;

            @media only screen and (max-width: $screenMedium) {
                width: 33.33333%;
            }

            @media only screen and (max-width: $screenSmall) {
                width: 50%;
            }
        }
    }
}

.no-data {
    margin: auto;
}
</style>