<template>
	<div class="container">
		<div class="loading" v-if="!isContentReady">
			<loader-content :isDisplay="true"></loader-content>
		</div>
		<policy-content :content="content" v-if="isContentReady"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';
import ContentService from '@/services/contentService';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			isContentReady: false,
			metaDescription: '',
			content: null
		};
	},
	mounted() {
        this.getContent();
    },
	methods: {
		async getContent() {
			this.isContentReady = false;

            const result = await ContentService.getHelpInfoTSI();

			if (result.data) {
				this.metaDescription = result.data.metaDescription;

				this.content = {
					title: result.data.title,
					detail: result.data.detail
				}
				
				this.isContentReady = true;
			}
		}
	},
	metaInfo() {
		const title = this.content ? this.content.title + ' | YELLOWTiRE' : Helper.metaDefault().title;
		const desc = this.metaDescription ? this.metaDescription : Helper.metaDefault().metaDescription;

        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: location.origin + Helper.metaDefault().logo },
				{ vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: location.origin + Helper.metaDefault().logo }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.loading {
	height: 500px;
}
</style>