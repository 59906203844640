<template>
    <section class="hero-banner" v-if="banner || !isBannerReady">
        <template v-if="isBannerReady">
            <component :is="banner.link ? 'a' : 'div'" :to="banner.link" :title="banner.title" :name="name" class="wrapper">
                <img :src="banner.img.desktop" :alt="banner.title" :title="banner.title" class="d-none d-md-block" />
                <img :src="banner.img.mobile" :alt="banner.title" :title="banner.title" class="d-md-none" />
            </component>
        </template>
    </section>
</template>

<script>
export default {
    props: {
        isBannerReady: Boolean,
        banner: Object,
        name: String
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.hero-banner {
    .wrapper {
        display: block;
        margin: auto;

        img {
            display: block;
            width: 100%;
        }
    }
}
</style>