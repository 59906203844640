import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';

export default {
    async getTopMenu() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/top-menu');
        
        return result;
    },
    async getSuggestTags() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/suggest-tags');
        
        return result;
    },
    async getAllTireSizeOption() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/tire-all-size');
        
        return result;
    },
    async getTireSizeByCondition(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/tire-size-by-condition', param);
        
        return result;
    },
    async getTireType() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/tire-type');
        
        return result;
    },
    async getTireWidth() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/tire-width');
        
        return result;
    },
    async getTireRatio(width) {
        const param = {
            width: width
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/tire-ratio', param);
        
        return result;
    },
    async getTireDiameter(width, ratio) {
        const param = {
            width: width,
            ratio: ratio
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/tire-diameter', param);
        
        return result;
    },
    async getTireWidthByModel(brand, model) {
        const param = {
            brand: brand,
            model: model
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/tire-width-by-model', param);
        
        return result;
    },
    async getTireRatioByModel(brand, model, width) {
        const param = {
            brand: brand,
            model: model,
            width: width
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/tire-ratio-by-model', param);
        
        return result;
    },
    async getTireDiameterByModel(brand, model, width, ratio) {
        const param = {
            brand: brand,
            model: model,
            width: width,
            ratio: ratio
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/tire-diameter-by-model', param);
        
        return result;
    },
	async getCarBrand() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/car-brand');
        
        return result;
    },
    async getCarModel(brand) {
        const param = {
            brand: brand
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/car-model', param);
        
        return result;
    },
    async getCarYear(brand, model) {
        const param = {
            brand: brand,
            model: model
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/car-year', param);
        
        return result;
    },
    async getProductBrand() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/product-brand');
        
        return result;
    },
    async getProductModel(brand) {
        const param = {
            brand: brand
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/product-model', param);
        
        return result;
    },
    async masterGetPromotionList(category) {
        const param = {
            category: category
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/promotion-list', param);
        
        return result;
    },
    async masterGetWarrantyList() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/warranty-list');
        
        return result;
    },
    async masterGetServiceList() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/service-list');
        
        return result;
    },
    async getProductPicture(brand, model) {
        const param = {
            brand: brand,
            model: model
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/get-product-picture', param);
        
        return result;
    },
    async getProductSku(brand, model, width, ratio, diameter) {
        const param = {
            brand: brand,
            model: model,
            width: width,
            ratio: ratio,
            diameter: diameter
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/get-product-sku', param, SellerService.setHeaderSellerToken());
        
        return result;
    },
    async getRegion() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/get-region');
        
        return result;
    },
    async getProvince(region) {
        const param = {
            region: region
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/get-province', param);
        
        return result;
    },
    async getDistrict(province) {
        const param = {
            province: province
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/get-district', param);
        
        return result;
    },
    async getSubDistrict(province, district) {
        const param = {
            province: province,
            district: district
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/master/get-sub-district', param);
        
        return result;
    },
    async getThaiAddressJson() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/master/address-data');
        
        return result;
    },
}